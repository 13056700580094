import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faCog, faSignOut} from '@fortawesome/free-solid-svg-icons';
import useOutsideAlerter from '../../utils/useOutsideAlerter';
import store from '../../store/store';
import {logoutAction} from '../../store/actions/userActions';
import {useSelector} from 'react-redux';
import ROUTES from '../../constants/routes';
import {useNavigate} from 'react-router-dom';

export default function UserDropdown() {

  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [open, setOpen] = useState(false);
  const handleClick = () => { setOpen(!open) };

  // Used to detect click outside of dropdown menu.
  const ref = useRef(null);
  useOutsideAlerter(ref, () => setOpen(false));

  return (
    <div className="user-dropdown" ref={ref}>
      <button className="user-dropdown__button" onClick={handleClick}>
        <span>{user.data.name}</span>
        <FontAwesomeIcon icon={faChevronDown} className="user-dropdown__button-icon" />
      </button>
      {open && (
        <div className="user-dropdown__menu" onClick={() => navigate(ROUTES.settings)}>
          <div className="user-dropdown__menu-item">
            <FontAwesomeIcon icon={faCog} className="user-dropdown__menu-icon" />
            Seaded
          </div>
          <div className="user-dropdown__menu-item" onClick={() => store.dispatch(logoutAction())}>
            <FontAwesomeIcon icon={faSignOut} className="user-dropdown__menu-icon" />
            Logi välja
          </div>
        </div>
      )}
    </div>
  );
}