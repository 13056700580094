export function getFromLocalStorage(item) {
  const data = localStorage.getItem(item);
  return JSON.parse(data);
}

export function saveToLocalStorage(item, data) {
  localStorage.setItem(item, JSON.stringify(data));
}

export function removeFromLocalStorage(item) {
  localStorage.removeItem(item);
}