import React from 'react';

export default function Input({
  type,
  label,
  value,
  active,
  onChange
}) {
  return (
    <label className="input">
      <input
        type={type ? type : 'text'}
        placeholder=" "
        className={`input__field ${active ? 'active' : ''}`}
        value={value}
        spellCheck={false}
        onChange={onChange}
      />
      <span className="input__label">{label}</span>
    </label>
  );
}