import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import {login} from '../../../services/UserService';

export default function LoginForm() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="form form--login">
      <Input
        type="email"
        label="E-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        type="password"
        label="Parool"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        primary
        round
        text="Logi sisse"
        onClick={() => login({ email, password })}
      />
      <Button
        primary
        outline
        round
        text="Pole kasutajat? Registreeruma"
        onClick={() => navigate(ROUTES.register)}
      />
    </div>
  );
}