import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fas);

export default function Icon({ name }) {
  return (
    <div className="icon">
      <FontAwesomeIcon icon={['fas', name]} />
    </div>
  );
}