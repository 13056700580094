import React from 'react';
import LayoutAuth from './LayoutAuth';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';

export default function Layout(props) {

  const renderLayout = () => {
    switch (props.type) {
      case 'auth':
        return <LayoutAuth {...props} />;
      default:
        return (
          <div className="layout layout--default">
            <Header />
            <Navigation />
            <main role="main">
              <div className="container">
                {props.children}
              </div>
            </main>
          </div>
        );
    }
  };

  return (renderLayout());
}