import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';

export default function Card({
  value,
  label,
  linkLabel,
  to,
  type
}) {
  return (
    <div className={`card ${type ?? ''}`}>
      <div className="card__data">
        {value}
      </div>
      <div className="card__info">
        <label className="card__label">{label ?? ''}</label>
        <Link className="card__link" to={to ?? '/'}>
          {linkLabel}
          <Icon name="chevron-right" />
        </Link>
      </div>
    </div>
  );
}