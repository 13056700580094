import React from 'react';
import Logo from '../Logo/Logo';

export default function LayoutAuth(props) {
  return (
    <div className="layout layout--auth">
      <div className="layout__container">
        <div className="layout__content layout__container--small">
          <Logo width={125} />
          {props.children}
        </div>
      </div>
    </div>
  );
}