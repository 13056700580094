import {USER_LOG_IN, USER_LOG_OUT} from '../../constants/actions';

const initialState = {
  loggedIn: false,
  data: {}
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOG_IN:
      return {
        ...state,
        loggedIn: true,
        data: action.payload.data
      };

    case USER_LOG_OUT:
      return {
        ...state,
        loggedIn: false,
        data: {}
      };

    default:
      return state;
  }
}