import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Layout from '../../components/Layout/Layout';
import Grid from '../../components/Grid/Grid';
import {useSelector} from 'react-redux';
import Input from '../../components/UI/Input/Input';

export default function Settings() {

  const user = useSelector(state => state.user);

  return (
    <Layout>
      <PageTitle title="Seaded" />
      <Grid.Container>
        <h1 className="title">Kasutaja seaded</h1>
        <Grid.Row md={2} lg={4}>
          <Input label="Teie nimi" value={user.data.name} disabled />
        </Grid.Row>
      </Grid.Container>
    </Layout>
  );
}