import axios from 'axios';
import API from '../constants/api';
import store from '../store/store';
import {loginAction} from '../store/actions/userActions';

export function login(data) {
  axios.post(API.ROUTES.login, data)
    .then(res => {
      if (res.data.success) {
        store.dispatch(loginAction(res.data.data));
      }
    })
    .catch(err => {
      console.log(err);
    });
}

export function register(data) {
  axios.post(API.ROUTES.register, data)
    .then(res => {
      if (res.data.success) {
        store.dispatch(loginAction(res.data.data));
      }
    })
    .catch(err => {
      console.log(err);
    });
}