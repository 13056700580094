import React from 'react';

export default function Table(props) {
  return (
    <div className="table">
      <table className="table__table" cellSpacing={0}>
        <thead>
          <tr>
            {props.headers?.map(header => <td>{header}</td>)}
          </tr>
        </thead>
        <tbody>
        {props.data?.map(dataRow => { return (
          <tr>
            {dataRow.map(value => <td dangerouslySetInnerHTML={{__html: value}}></td>)}
          </tr>
        )})}
        </tbody>
      </table>
    </div>
  );
}