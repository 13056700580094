import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import Logo from './../../images/bookline.svg';
import Icon from '../Icon/Icon';

export default function Navigation() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', open);
  }, [open]);

  return (
    <nav className="navigation">
      <div className="navigation__container">
        <img src={Logo} alt="Bookline.ee" />
        <div className="navigation__collapse" onClick={() => setOpen(!open)}>
          <Icon name="bars" />
        </div>
        <div className={`navigation__links ${open ? 'open' : ''}`}>
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/">Töölaud</NavLink>
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/orders">Tellimused</NavLink>
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/forms">Vormid</NavLink>
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/users">Kasutajad</NavLink>
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/help">Abi</NavLink>
        </div>
      </div>
    </nav>
  );
}