import React from 'react';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';

export default function Users() {
  return (
    <Layout>
      <PageTitle title="Kasutajad" />
    </Layout>
  );
}