import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {useSelector} from 'react-redux';
import ROUTES from '../constants/routes';
import authUser from './../utils/auth';
import './../styles/_index.scss';

// Route containers
import PublicRoutes from './Routes/PublicRoutes';
import ProtectedRoutes from './Routes/ProtectedRoutes';

// Pages
import Login from './../pages/auth/Login';
import Register from './../pages/auth/Register';
import Dashboard from './../pages/dashboard/Dashboard';
import Orders from '../pages/dashboard/Orders';
import Forms from '../pages/dashboard/Forms';
import Users from '../pages/dashboard/Users';
import Help from '../pages/dashboard/Help';
import NotFound from './../pages/error/NotFound';
import Settings from '../pages/user/Settings';

// Check, if user was logged in.
authUser();

export default function App() {

  const loggedIn = useSelector(state => state.user.loggedIn);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          {/* Protected routes */}
          <Route path={ROUTES.root} element={<ProtectedRoutes loggedIn={loggedIn} />}>
            <Route path={ROUTES.root} element={<Dashboard />} />
            <Route path={ROUTES.orders} element={<Orders />} />
            <Route path={ROUTES.forms} element={<Forms />} />
            <Route path={ROUTES.users} element={<Users />} />
            <Route path={ROUTES.help} element={<Help />} />
            <Route path={ROUTES.settings} element={<Settings />} />
          </Route>

          {/* Public routes */}
          <Route path={ROUTES.root} element={<PublicRoutes loggedIn={loggedIn} />}>
            <Route path={ROUTES.login} element={<Login />} exact />
            <Route path={ROUTES.register} element={<Register />} exact />
          </Route>

          {/* Page not found */}
          <Route path="*" element={<NotFound />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}