import React from 'react';
import Language from '../Language/Language';
import UserDropdown from '../User/UserDropdown';

export default function Header() {
  return (
    <header className="header">
      <div className="header__container">
        <div className="header__section">
          <div className="header__section-placeholder">DEVINVESTORY OÜ</div>
        </div>
        <div className="header__section">
          <Language />
          <UserDropdown />
        </div>
      </div>
    </header>
  );
}