import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import {register} from '../../../services/UserService';

export default function RegisterForm() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  return (
    <div className="form form--register">
      <h2>Registreerumine</h2>
      <h3>Nimi</h3>
      <Input
        type="text"
        label="Eesnimi"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <Input
        type="text"
        label="Perekonnanimi"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <h3>Kasutaja</h3>
      <Input
        type="email"
        label="E-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        type="password"
        label="Parool"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Input
        type="password"
        label="Korrake parooli"
        value={passwordRepeat}
        onChange={(e) => setPasswordRepeat(e.target.value)}
      />
      <Button
        primary
        round
        text="Registreeru"
        onClick={() => register({
          name: `${firstName} ${lastName}`,
          email,
          password,
          confirm_password: passwordRepeat
        })}
      />
      <Button
        primary
        outline
        round
        text="Kasutaja olemas? Logi sisse"
        onClick={() => navigate(ROUTES.login)}
      />
    </div>
  );
}