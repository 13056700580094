import React from 'react';

export default function Button({
  text,
  round,
  outline,
  primary,
  onClick
}) {

  const getClassNames = () => {
    let classes = 'button';

    if (round) { classes += ' round'; }
    if (outline) { classes += ' outline'; }
    if (primary) { classes += ' button__primary'; }

    return classes;
  };

  return (
    <button
      type="button"
      className={getClassNames()}
      onClick={onClick}
    >
      {text}
    </button>
  );
}