const ROUTES = {

  // Auth
  login: '/login',
  register: '/register',

  // Dashboard
  root: '/',
  orders: '/orders',
  forms: '/forms',
  users: '/users',
  help: '/help',
  settings: '/settings',

};

export default ROUTES;