import React from 'react';
import Layout from '../../components/Layout/Layout';
import RegisterForm from '../../components/Forms/Auth/RegisterForm';
import PageTitle from '../../components/PageTitle/PageTitle';

export default function Register() {
  return (
    <Layout type="auth">
      <PageTitle title="Registreerumine" />
      <RegisterForm />
    </Layout>
  );
}