import React from 'react';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';
import Grid from '../../components/Grid/Grid';
import Table from '../../components/Table/Table';
import TableFilter from '../../components/Table/TableFilter';

export default function Orders() {
  return (
    <Layout>
      <PageTitle title="Tellimused" />
      <Grid.Container>
        <h1 className="title">Tellimused</h1>
        <TableFilter lg={5}>
          <TableFilter.Filter active label="Tellimuse number" />
          <TableFilter.Filter active label="Kuupäev ja kellaaeg" />
          <TableFilter.Filter active label="Teenuse nimetus" />
          <TableFilter.Filter active label="Kliendi nimi" />
          <TableFilter.Button primary round text="OTSI" />
        </TableFilter>
        <Table
          headers={['Kood', 'Kuupäev ja kellaaeg', 'Teenuse nimetus', 'Kliendi nimi', 'Telefon', 'E-mail', '']}
          data={[
            ['1111', '21.11.2022 21:30', '<b>Bookline demonstratsioon</b>', 'John Doe', '+372 56684899', 'demo@bookline.ee', ''],
            ['1110', '21.11.2022 20:30', '<b>Bookline demonstratsioon</b>', 'Jane Doe', '+372 56684899', 'demo@bookline.ee', '']
          ]}
        />
      </Grid.Container>
    </Layout>
  );
}