import React from 'react';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';

const TableFilter = (props) => {
  let filterClasses = 'table__filter';

  if (props.xs) { filterClasses += ` xs-${props.xs}`;  }
  if (props.sm) { filterClasses += ` sm-${props.sm}`;  }
  if (props.md) { filterClasses += ` md-${props.md}`;  }
  if (props.lg) { filterClasses += ` lg-${props.lg}`;  }
  if (props.xl) { filterClasses += ` xl-${props.xl}`;  }
  if (props.xxl){ filterClasses += ` xxl-${props.xxl}`;}

  return <div className={filterClasses}>{props.children}</div>;
};

const Filter = (props) => {
  return (
    <div className="table__filter-input">
      <Input {...props} />
    </div>
  );
};

const Btn = (props) => {
  return <Button {...props} />;
};

TableFilter.Filter = Filter;
TableFilter.Button = Btn;

export default TableFilter;