import React from 'react';
import Layout from '../../components/Layout/Layout';
import LoginForm from '../../components/Forms/Auth/LoginForm';
import PageTitle from '../../components/PageTitle/PageTitle';

export default function Login() {
  return (
    <Layout type="auth">
      <PageTitle title="Logi sisse" />
      <LoginForm />
    </Layout>
  );
}