import {removeFromLocalStorage, saveToLocalStorage} from '../../utils/storage';
import * as action from '../../constants/actions';

export function loginAction(data) {
  saveToLocalStorage('user', data);
  return {
    type: action.USER_LOG_IN,
    payload: { data }
  };
}

export function logoutAction() {
  removeFromLocalStorage('user');
  return {
    type: action.USER_LOG_OUT
  };
}