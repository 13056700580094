import React from 'react';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';
import Card from '../../components/Card/Card';
import ROUTES from '../../constants/routes';
import Grid from '../../components/Grid/Grid';

export default function Dashboard() {
  return (
    <Layout>
      <PageTitle title="Töölaud" />
      {/* Shortcuts */}
      <Grid>
        <Grid.Row xs={1} sm={2} lg={4}>
          <Grid.Col>
            <Card
              value={0}
              label="Uusi tellimusi"
              linkLabel="Vaata kõiki tellimusi"
              to={ROUTES.orders}
            />
          </Grid.Col>
          <Grid.Col>
            <Card
              value={0}
              label="Vorme kasutusel"
              linkLabel="Vaata kõiki vorme"
              to={ROUTES.forms}
            />
          </Grid.Col>
          <Grid.Col>
            <Card
              value={1}
              label="Kasutajaid"
              linkLabel="Vaata kõiki kasutajaid"
              to={ROUTES.users}
            />
          </Grid.Col>
          <Grid.Col>
            <Card
              type="success"
              value={0}
              label="Maksmata arvet"
              linkLabel="Vaata kõiki arveid"
              to={ROUTES.root}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>

    </Layout>
  );
}