import store from '../store/store';
import {getFromLocalStorage} from './storage';
import {loginAction} from '../store/actions/userActions';

/**
 * Checks if user data exists in local storage.
 * Checks if user is already logged in.
 */
export default function authUser() {
  const user = getFromLocalStorage('user');

  if (user && user.token) {
    store.dispatch(loginAction(user));
  }
}