const URL = process.env.REACT_APP_API_URL_ROOT;

const API = {
  ROUTES: {

    // CSRF
    csrf: `${URL}/sanctum/csrf-cookie`,

    // User routes
    login: `${URL}/login`,
    register: `${URL}/register`,

  },
};

export default API;