import React from 'react';
import LogoImage from './../../images/bookline.svg';

export default function Logo(props) {
  return (
    <img
      {...props}
      src={LogoImage}
      alt="Bookline.ee"
      className="logo"
    />
  );
}