import React from 'react';

const Grid = (props) => {
  return <div className="grid" {...props}>{props.children}</div>;
};

const Container = (props) => {
  return <div className="grid__container">{props.children}</div>;
};

const Row = (props) => {
  let rowClasses = 'grid__row';

  if (props.xs) { rowClasses += ` xs-${props.xs}`;  }
  if (props.sm) { rowClasses += ` sm-${props.sm}`;  }
  if (props.md) { rowClasses += ` md-${props.md}`;  }
  if (props.lg) { rowClasses += ` lg-${props.lg}`;  }
  if (props.xl) { rowClasses += ` xl-${props.xl}`;  }
  if (props.xxl){ rowClasses += ` xxl-${props.xxl}`;}

  return <div className={rowClasses}>{props.children}</div>;
};

const Col = (props) => {
  return <div className="grid__col">{props.children}</div>;
};

Grid.Container = Container;
Grid.Row = Row;
Grid.Col = Col;

export default Grid;